import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./App.css";
import carPic from "./Images/pngwing.com.png";
import AddToHomeScreen from "@ideasio/add-to-homescreen-react";
import { useStoreState, useStoreActions } from "easy-peasy";
import ChargingSwitch from "./Components/ChargingSwitch";

import { GetMuuntuvaIndex, GetChargeStatus, ChargeStart, ChargeStop } from "./Services/API/MuuntuvaAPI";
import DeviceId from "./Components/DeviceId";
import { ChargingState } from "./Util";

// import useFetch from "./Hooks/useFetch"; NOTE: This is good also

const App = () => {
  const [chargingStatus, setChargingStatus] = useState(false);
  const [data, setData] = useState("n/a");

  const { userName, deviceId } = useStoreState((state) => ({
    userName: state.userName,
    deviceId: state.deviceId,
  }));

  const { setUserName, setDeviceId } = useStoreActions((state) => ({
    setUserName: state.setUserName,
    setDeviceId: state.setDeviceId,
  }));

  const getChargeStatus = async () => {
    const result = await GetChargeStatus(deviceId);

    // console.log("APP: charge status = " + JSON.stringify(result, null, 2));

    if (result.status && result.status === true) {
      setChargingStatus(true);
    } else {
      setChargingStatus(false);
    }
  };

  // const getIndex = async () => {
  //   const result = await GetMuuntuvaIndex();

  //   setData(result);
  // };

  useEffect(() => {
    getChargeStatus();
    // getIndex();
  }, []);

  // const onChargeButtonClick = async (event) => {
  //   console.log("onChargeButtonClick");

  //   const result = chargingStatus ? await ChargeStop(deviceId) : await ChargeStart(deviceId);

  //   if (result.status && result.status === true) {
  //     setChargingStatus(true);
  //   } else {
  //     setChargingStatus(false);
  //   }
  // };

  const handleChange = async (switchState) => {
    console.log("handleChange, event = " + switchState);

    const result = switchState ? await ChargeStart(deviceId) : await ChargeStop(deviceId);

    console.log("result: " + JSON.stringify(result, null, 2));

    if (result.status && result.status === true) {
      setChargingStatus(true);
    } else {
      setChargingStatus(false);
    }
  };

  const onSaveDeviceId = (value) => {
    console.log("App.js Saving device id " + value);
    setDeviceId(value);
  };

  return (
    <div style={{ border: "3px solid lightgreen", borderRadius: 15, margin: 10, padding: 10 }}>
      <Container fluid style={{ background: "white"}}>
        <Row>
          <Col style={{ background: "#e6ffe6" }}>
            <span style={{ marginLeft: 5 }}>MuuntuvaMobile</span>
          </Col>
        </Row>
        <Row style={{ marginTop: 30, textAlign: "center" }}>
          <Col>
            <img src={carPic} alt='CarPic' style={{ width: 300 }} />
          </Col>
        </Row>
        <Row style={{ marginTop: 30, textAlign: "center" }}>
          <Col>
            <code>{deviceId}</code>
          </Col>
        </Row>
        <Row style={{ marginTop: 30, textAlign: "center" }}>
          <Col>
            <ChargingSwitch deviceId={deviceId} charging={chargingStatus} onChangeCallback={handleChange} onSaveCallback={onSaveDeviceId}/>
          </Col>
        </Row>

        {/* <Row style={{ marginTop: 30, textAlign: "center" }}>
          <Col>
            {!chargingStatus && <Button onClick={onChargeButtonClick}>Lataa</Button>}
            {chargingStatus && <Button onClick={onChargeButtonClick}>Pysäytä lataus</Button>}
          </Col>
        </Row> */}
        <Row style={{ marginTop: 130, textAlign: "center" }}>
          <Col>
            {data?.title && (
              <code>
                {data.title} {data.currentdate}
              </code>
            )}
            <code>(2023-03-27)</code>
          </Col>
        </Row>

        {/* <AddToHomeScreen 
      delayNotify={3000} 
      skipFirstVisit={false} 
      translate={{headLine: 'Hed lain', bottomLine: 'Pottom lain', chromiumAddHomeScreen:'Chrome'}}
      />       */}
        <AddToHomeScreen />
      </Container>
    </div>
  );
};

export default App;
