import axios from "axios";
import API_URL_BASE from "../../Config"; // https://koodipaja.fi/muuntuva-api";
// import { getToken } from "../../Utils/Storage";

export const URIS = {
  INDEX: "",
  CHARGE_STATUS: "device/status",
  CHARGE_START: "device/start",
  CHARGE_STOP: "device/stop",
};

export const BACKEND_STATUS = {
  SUCCESS: 200,
  FAILED: "failed",
};

console.log("API_URL_BASE = " + JSON.stringify(API_URL_BASE.API_URL_BASE));

// TODO: Add token to header
const defaultOptions = {
  baseURL: API_URL_BASE.API_URL_BASE,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-session-token": "",
    "Access-Control-Allow-Origin": "*",
  },
};

export const ApiClient = axios.create(defaultOptions);

// ApiClient.interceptors.request.use(function (config) {
// const token = getToken();

// config.headers.Authorization = token ? `Bearer ${token}` : "";
// config.headers["x-session-token"] = token;

//  return config;
// });

// TODO: This could be done such as: function getApiClientInstance .. which would contain some initializations (token etc)
