import { URIS, BACKEND_STATUS, ApiClient } from "./ApiClient.js";
import { resolve } from "./Resolve.js";

const communicate = async (url, params) => {
  const result = await resolve(ApiClient.get(url, { params: params }));

  if (result?.status === BACKEND_STATUS.SUCCESS) {
    if (result?.data) {
      // console.log("return: " + JSON.stringify(result.data, null, 2));
      return result.data;
    }
  }

  return result;
};

export const GetMuuntuvaIndex = async () => {
  console.log("GetMuuntuvaIndex");

  return communicate(URIS.INDEX, {});
};

export const GetChargeStatus = async (deviceId) => {
  // TODO: Remove this
  deviceId = 1;

  console.log("GetChargeStatus: " + URIS.CHARGE_STATUS + "/" + deviceId);

  return communicate(URIS.CHARGE_STATUS + "/" + deviceId, {});
};

export const ChargeStart = async (deviceId) => {
  console.log("ChargeStart: " + URIS.CHARGE_START + "/" + deviceId);

  return communicate(URIS.CHARGE_START + "/" + deviceId, {});
};

export const ChargeStop = async (deviceId) => {
  console.log("ChargeStop: " + URIS.CHARGE_STOP + "/" + deviceId);

  return communicate(URIS.CHARGE_STOP + "/" + deviceId, {});
};
