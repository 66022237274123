import React, { useState, useEffect } from "react";
import { FaWrench } from "react-icons/fa";
import { Container, Row, Col, Button, Form, Stack } from "react-bootstrap";
import Switch from "react-switch";
import "./Styles.css";
import { ChargingState } from "../Util";

const ChargingSwitch = ({ deviceId, charging, onChangeCallback, onSaveCallback }) => {
  const [isEnabled, setIsEnabled] = useState(charging);
  const [isInput, setIsInput] = useState(false);
  const [deviceIdValue, setDeviceIdValue] = useState("");
  const [initialized, setInitialized] = useState(false);

  const toggleSwitch = (state) => {
    if (state != isEnabled) {
      setIsEnabled((previousState) => !previousState);
      onChangeCallback(state);
    }
  };

  useEffect(() => {
    // if (charging === ChargingState.UNKNOWN) {
    //   console.log("____Skip charging first time____");
    //   return;
    // }

    // if (charging === ChargingState.STARTED) {
    //   setIsEnabled(true);
    // } else {
    //   setIsEnabled(false);
    // }

    setIsEnabled(charging);
  }, [charging]);

  useEffect(() => {
    // console.log("ChargingSwitch, INIT, charging = " + charging);

    // if (charging === ChargingState.STARTED) {
    //   setIsEnabled(true);
    // } else {
    //   setIsEnabled(false);
    // }

    setInitialized(true);
  }, []);

  // useEffect(() => {
  //   if (initialized) {
  //     console.log("Calling onChangeCallback, " + JSON.stringify(isEnabled));
  //     // onChangeCallback(isEnabled);
  //   } else {
  //     console.log("SKIP change callback");
  //   }
  // }, [isEnabled]);

  const onSaveClick = () => {
    console.log("Debug: click " + deviceIdValue);
    var jaa = deviceIdValue;
    onSaveCallback(jaa);
    setIsInput(false);
  };

  const onTextChange = (value) => {
    console.log("value = " + value);
    setDeviceIdValue(value);
  };

  const getInputField = () => {
    return (
      <Col>
        <Form>
          <Form.Group className='mb-4' controlId='formDeviceId'>
            <Form.Label className='device-id-input'>Device id</Form.Label>
            <Form.Control type='text' defaultValue={deviceId} placeholder='Enter device id' onChange={(event) => onTextChange(event?.target.value)} />
          </Form.Group>
          {deviceId != deviceIdValue && (
            <Button variant='outline-success' onClick={onSaveClick}>
              Save
            </Button>
          )}
          {deviceId === deviceIdValue && (
            <Button
              variant='outline-success'
              onClick={() => {
                setIsInput(false);
              }}>
              Close
            </Button>
          )}
        </Form>
      </Col>
    );
  };

  return (
    <div className='switch-container'>
      <div className='charging-switch'>
        <Row>
          <Col className='device-caption'>Lataus</Col>
        </Row>
        <Row className='align-items-center'>
          {!isInput && (
            <Col className='device-id'>
              <Stack gap={2} direction='horizontal' className='col-md-5 mx-auto'>
                <div>{deviceId}</div>
                <div>
                  <Button
                    variant='link'
                    onClick={() => {
                      setDeviceIdValue(deviceId);
                      setIsInput(true);
                    }}>
                    <FaWrench color='green' />
                  </Button>
                </div>
              </Stack>
            </Col>
          )}
          {isInput && getInputField()}
          <Col className='align-items-center'>{!isInput && <Switch onChange={toggleSwitch} checked={isEnabled} />}</Col>
        </Row>
      </div>
    </div>
  );
};

export default ChargingSwitch;
