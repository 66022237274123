import { action } from "easy-peasy";

const ApplicationModel = {
    userName: "User_0",
    deviceId: " ",
 
    setUserName: action((state, userName) => {
      console.log("Setting user name: " + JSON.stringify(userName));
      state.userName = userName;
    }),

    setDeviceId: action((state, deviceId) => {
      console.log("Store - Setting device id: " + JSON.stringify(deviceId));
      state.deviceId = deviceId;
    }),

  };

  export default ApplicationModel;